import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NewsletterService from '../../services/NewsletterService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

// Helper function to format dates in dd/mm/yyyy
const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const Newsletters = () => {
  const navigate = useNavigate();

  const [newsletters, setNewsletters] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    email: '',
    locale: '',
    enabled: '',
    registrationDate: ''
  });

  const fetchData = async (page) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        const data = await NewsletterService.getNewsletterData(authToken, page, pageSize, filters);
        setNewsletters(data.content);
        setTotalPages(data.totalPages);
      } else {
        setError('No auth token found');
        handleLogout();
        return;
      }
    } catch (error) {
      if (error.message === 'Unauthorized') {
        handleLogout();
      } else {
        setError(error.message);
      }    
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };
  
  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, filters]);

  const handlePageChange = (page) => {
    if (page >= 0 && page < totalPages) {
      setCurrentPage(page);
      setIsLoading(true);
    }
  };

  const handleEnabledChange = async (newsletterId, newEnabled) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        await NewsletterService.updateNewsletterEnabled(authToken, newsletterId, newEnabled);
        fetchData(currentPage);
      } else {
        setError('No auth token found');
        handleLogout();
        return;
      }
    } catch (error) {
      if (error.message === 'Unauthorized') {
        handleLogout();
      } else {
        setError(error.message);
      }  
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedNewsletters = [...newsletters].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return <FontAwesomeIcon icon={faSortUp} />;
      } else {
        return <FontAwesomeIcon icon={faSortDown} />;
      }
    } else {
      return <FontAwesomeIcon icon={faSort} />;
    }
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <section className="body">
      <header className="page-header">
        <h2>Newsletters</h2>
      </header>

      <section className="panel">
        <header className="panel-heading">
          <h2 className="panel-title">Newsletter List</h2>
          <div className="panel-body">
            <div className="filter-form row">
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  placeholder="Email"
                  value={filters.email}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control"
                  name="locale"
                  placeholder="Locale"
                  value={filters.locale}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="col-md-3">
                <select
                  name="enabled"
                  className="form-control"
                  value={filters.enabled}
                  onChange={handleFilterChange}
                >
                  <option value="">All Enabled States</option>
                  <option value="true">Enabled</option>
                  <option value="false">Disabled</option>
                </select>
              </div>
              <div className="col-md-3">
                <input
                  type="date"
                  className="form-control"
                  name="registrationDate"
                  value={filters.registrationDate}
                  onChange={handleFilterChange}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="panel-body">
          <div className="table-responsive">
            <table className="table table-bordered table-striped mb-none">
              <thead>
                <tr>
                  <th onClick={() => handleSort('email')} style={{ whiteSpace: 'nowrap' }}>
                    Email {getSortIcon('email')}
                  </th>
                  <th onClick={() => handleSort('locale')} style={{ whiteSpace: 'nowrap' }}>
                    Locale {getSortIcon('locale')}
                  </th>
                  <th onClick={() => handleSort('enabled')} style={{ whiteSpace: 'nowrap' }}>
                    Enabled {getSortIcon('enabled')}
                  </th>
                  <th onClick={() => handleSort('registrationDate')} style={{ whiteSpace: 'nowrap' }}>
                    Registration Date {getSortIcon('registrationDate')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedNewsletters.map(newsletter => (
                  <tr key={newsletter.id}>
                    <td>{newsletter.email}</td>
                    <td>{newsletter.locale}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={newsletter.enabled}
                        onChange={(e) => handleEnabledChange(newsletter.id, e.target.checked)}
                      />
                    </td>
                    <td>{formatDate(newsletter.registrationDate)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <div className="pagination-container">
        <ul className="pagination">
          <li className={currentPage === 0 ? 'disabled' : ''}>
            <a onClick={() => handlePageChange(currentPage - 1)}>Previous</a>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index} className={index === currentPage ? 'active' : ''}>
              <a onClick={() => handlePageChange(index)}>{index + 1}</a>
            </li>
          ))}
          <li className={currentPage === totalPages - 1 ? 'disabled' : ''}>
            <a onClick={() => handlePageChange(currentPage + 1)}>Next</a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Newsletters;
