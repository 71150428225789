import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardService from '../../services/DashboardService';

const Dashboard = () => {
  const navigate = useNavigate();

  const [data, setDashboardData] = useState(null); // Inicialmente null
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        const result = await DashboardService.getDashboardData(authToken);
        setDashboardData(result);
      } else {
        setError('No auth token found');
        handleLogout();
        return;
      }
    } catch (error) {
      if (error.message === 'Unauthorized') {
        handleLogout();
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <p>Loading...</p>; // Muestra un indicador de carga mientras esperas los datos
  }

  if (error) {
    return <p>{error}</p>; // Muestra el error si lo hay
  }

  // Agregar verificación de `data` antes de intentar acceder a sus propiedades
  if (!data) {
    return <p>No data available</p>;
  }

  return (
    <section className="body">
    <header className="page-header">
      <h2>Dashboard</h2>
    </header>

    <div className="row">
      <div className="col-md-6 col-lg-12 col-xl-6">
        <div className="row">
          <div className="col-md-12 col-lg-6 col-xl-6">
            <section className="panel panel-featured-left panel-featured-primary">
              <div className="panel-body">
                <div className="widget-summary">
                  <div className="widget-summary-col widget-summary-col-icon">
                    <div className="summary-icon bg-primary">
                      <i className="fa fa-home"></i>
                    </div>
                  </div>
                  <div className="widget-summary-col">
                    <div className="summary">
                      <h4 className="title">Product page contacts</h4>
                      <div className="info">
                        <strong className="amount">{data.countContactProductsPage}</strong>
                        <span className="text-primary">({data.countNewsContactProductsPage} new)</span>
                      </div>
                    </div>
                    <div className="summary-footer">
                      <a className="text-muted text-uppercase" href="#">(view all)</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="col-md-12 col-lg-6 col-xl-6">
            <section className="panel panel-featured-left panel-featured-secondary">
              <div className="panel-body">
                <div className="widget-summary">
                  <div className="widget-summary-col widget-summary-col-icon">
                    <div className="summary-icon bg-secondary">
                      <i className="fa fa-dollar"></i>
                    </div>
                  </div>
                  <div className="widget-summary-col">
                    <div className="summary">
                      <h4 className="title">Investment page contacts</h4>
                      <div className="info">
                        <strong className="amount">{data.countContactInvestmentsPage}</strong>
                        <span className="text-primary">({data.countNewContactInvestmentsPage} new)</span>
                      </div>
                    </div>
                    <div className="summary-footer">
                      <a className="text-muted text-uppercase" href="#">(withdraw)</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="col-md-12 col-lg-6 col-xl-6">
            <section className="panel panel-featured-left panel-featured-tertiary">
              <div className="panel-body">
                <div className="widget-summary">
                  <div className="widget-summary-col widget-summary-col-icon">
                    <div className="summary-icon bg-tertiary">
                      <i className="fa fa-envelope"></i>
                    </div>
                  </div>
                  <div className="widget-summary-col">
                    <div className="summary">
                      <h4 className="title">Total newsletter registrations</h4>
                      <div className="info">
                        <strong className="amount">{data.countNewsletterPage}</strong>
                      </div>
                    </div>
                    <div className="summary-footer">
                      <a className="text-muted text-uppercase" href="#">(statement)</a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className="col-md-12 col-lg-6 col-xl-6">
            <section className="panel panel-featured-left panel-featured-quartenary">
              <div className="panel-body">
                <div className="widget-summary">
                  <div className="widget-summary-col widget-summary-col-icon">
                    <div className="summary-icon bg-quartenary">
                      <i className="fa fa-users"></i>
                    </div>
                  </div>
                  <div className="widget-summary-col">
                    <div className="summary">
                      <h4 className="title">Total visitors to the pages (last 30 days)</h4>
                      <div className="info">
                        <strong className="amount">
                          <span>Investments: </span>{data.countInvestmentsPageVisitorsLast30Days}
                        </strong>
                        <span className="text-primary">({data.countInvestmentsPageVisitorsLast24Hours} last 24 hours)</span>
                        <br />
                        <strong className="amount">
                          <span>Products: </span>{data.countProductsPageVisitorsLast30Days}
                        </strong>
                        <span className="text-primary">({data.countProductsPageVisitorsLast24Hours} last 24 hours)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
};

export default Dashboard;
