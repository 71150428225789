import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginService } from '../../services/LoginService';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const rememberedUsername = localStorage.getItem('rememberedUsername');
    if (rememberedUsername) {
      setUsername(rememberedUsername);
      setRememberMe(true);
    }

    // Redirigir al dashboard si ya está autenticado
    if (localStorage.getItem('authToken')) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = await LoginService.login(username, password);

      // Guardar token en localStorage
      localStorage.setItem('authToken', data.token);
      localStorage.setItem('displayName', data.name);

      if (rememberMe) {
        localStorage.setItem('rememberedUsername', username);
      } else {
        localStorage.removeItem('rememberedUsername');
      }

      // Redirigir al dashboard después de iniciar sesión
      navigate('/dashboard');
    } catch (error) {
      setError('Login failed. Please check your username and password.');
    }
  };

  return (
    <section className="body-sign">
      <div className="center-sign">
        <div className='font-logo custom-primary-color'>
          <img src="img/san-partners-black.svg" alt="San & Partners Logo" className="logo-img" />
          San&<span>Partners</span>
        </div>

        <div className="panel panel-sign">
          <div className="panel-title-sign mt-xl text-right">
            <h2 className="title text-uppercase text-bold m-none">
              <i className="fa fa-user mr-xs"></i> Sign In
            </h2>
          </div>
          <div className="panel-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group mb-lg">
                <label>Username</label>
                <div className="input-group input-group-icon">
                  <input
                    name="username"
                    type="text"
                    className="form-control input-lg"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <span className="input-group-addon">
                    <span className="icon icon-lg">
                      <i className="fa fa-user"></i>
                    </span>
                  </span>
                </div>
              </div>

              <div className="form-group mb-lg">
                <label>Password</label>
                <div className="input-group input-group-icon">
                  <input
                    name="password"
                    type="password"
                    className="form-control input-lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <span className="input-group-addon">
                    <span className="icon icon-lg">
                      <i className="fa fa-lock"></i>
                    </span>
                  </span>
                </div>
              </div>

              {error && <p className="text-danger text-center">{error}</p>}

              <div className="row">
                <div className="col-sm-8">
                  <div className="checkbox-custom checkbox-default">
                    <input
                      id="RememberMe"
                      name="rememberme"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label htmlFor="RememberMe">Remember Me</label>
                  </div>
                </div>
                <div className="col-sm-4 text-right">
                  <button type="submit" className="btn btn-primary hidden-xs">Sign In</button>
                  <button type="submit" className="btn btn-primary btn-block btn-lg visible-xs mt-lg">Sign In</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <p className="text-center text-muted mt-md mb-md">
          &copy; Copyright 2024. All rights reserved. San&Partners.
        </p>
      </div>
    </section>
  );
};

export default Login;
