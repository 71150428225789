const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;

class NewsletterService {

    static async getNewsletterData(token, page, size, filters) {
        const queryParams = new URLSearchParams({
            page,
            size,
            email: filters.email || '',
            locale: filters.locale || '',
            enabled: filters.enabled || '',
            registrationDate: filters.registrationDate || ''
        }).toString();

        const response = await fetch(`${apiUrl}/newsletter?${queryParams}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        });

        if (!response.ok) {
            if (response.status === 403) {
                throw new Error('Unauthorized');
            }
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    static async updateNewsletterEnabled(token, newsletterId, enabled) {
        const response = await fetch(`${apiUrl}/newsletter/${newsletterId}/enabled`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ enabled }),
        });

        if (!response.ok) {
            if (response.status === 403) {
                throw new Error('Unauthorized');
            }
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    }
}

export default NewsletterService;
