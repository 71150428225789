class DashboardService {
    static async getDashboardData(token) {
        const apiUrl = process.env.REACT_APP_LOAD_PAGE_URL;
        const response = await fetch(`${apiUrl}/dashboard`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            if (response.status === 403) {
                throw new Error('Unauthorized');
            }
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    }
}

export default DashboardService;