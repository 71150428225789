import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const SidebarLeft = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    // Solo cambia la clase si estás en un tamaño de pantalla adecuado
    if (window.innerWidth >= 768) {
      document.documentElement.classList.toggle('sidebar-left-collapsed');
    }
  };

  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      setIsCollapsed(false); // Cierra el sidebar en pantallas pequeñas
      document.documentElement.classList.remove('sidebar-left-opened');
    }
  };

  useEffect(() => {
    const updateSidebarClass = () => {
      if (isCollapsed) {
        document.documentElement.classList.add('sidebar-left-collapsed');
      } else {
        document.documentElement.classList.remove('sidebar-left-collapsed');
      }
    };

    updateSidebarClass();
    
    // Cleanup function to remove classes if needed
    return () => {
      document.documentElement.classList.remove('sidebar-left-collapsed');
    };
  }, [isCollapsed]);

  return (
    <aside id="sidebar-left" className={`sidebar-left ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <div className="sidebar-title">Navigation</div>
        <div className="sidebar-toggle" onClick={toggleSidebar}>
          <i className="fa fa-bars" aria-label="Toggle sidebar"></i>
        </div>
      </div>

      <div className="nano">
        <div className="nano-content">
          <nav id="menu" className="nav-main" role="navigation">
            <ul className="nav nav-main">
              <li className="nav-active">
                <Link to="/dashboard" onClick={handleLinkClick}>
                  <i className="fa fa-home" aria-hidden="true"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/contacts" onClick={handleLinkClick}>
                  <i className="fa fa-users" aria-hidden="true"></i>
                  <span>Contacts</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/newsletter" onClick={handleLinkClick}>
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span>Newsletter</span>
                </Link>
              </li>
              <li>
                <Link to="/dashboard/campaigns" onClick={handleLinkClick}>
                  <i className="fa fa-copy" aria-hidden="true"></i>
                  <span>Campaigns</span>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </aside>
  );
};

export default SidebarLeft;
